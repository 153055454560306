import './index.css';
import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigRoutes } from 'ConfigRoutes';

ReactDOM.render(
    
    <ConfigRoutes />
  ,
  document.getElementById('root')
);
