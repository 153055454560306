import React from 'react'
import './PopupConfirmChangesContentObjects.css'


export const PopupConfirmChangesContentObjects = ({content}) => {


  return (
    <div className= 'scroll popup_confirm_changes_content_objects'>
      {content}
    </div>
  )
}
